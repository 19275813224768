import $ from "@modules/jquery";

$(document).ready(function() {
      
    const mediaQuery = setInterval(() => {
        if (Foundation.MediaQuery.queries.length === 0) {
          return;
        }
    
        if (Foundation.MediaQuery.current !== "") {
          clearInterval(mediaQuery);
        }
    
        if (Foundation.MediaQuery.upTo("md") ) {
          const columns = document.querySelectorAll(".wp-block-column");
          columns.forEach((column) => {
            column.style.order = "1";
            if (column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0) {
              column.style.order = "2";
            }
          });
        }
      });
 
    jQuery(window).on("changed.zf.mediaquery", function (event, newSize, oldSize) {
        if (Foundation.MediaQuery.queries.length === 0) {
            return;
        }
        if (Foundation.MediaQuery.upTo("md")) {
            const columns = document.querySelectorAll(".wp-block-column");
            columns.forEach((column) => {
            column.style.order = "1";
            if (
                column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0
            ) {
                column.style.order = "2";
            }
            });
        }
        else {
            const columns = document.querySelectorAll(".wp-block-column");
            columns.forEach((column) => {
                column.style.order = "1";
            });
        }
    });
});
